import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import NewDevelopmentsEdit from "@components/new-developments/Edit"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "new-developments.edit"
}

const NewDevelopmentsEditPage = (props) => (
  <>
    <Seo title="Edit New Developments" />
    <div className="main-wrapper">
      <Sidebar menuActive="new-developments"
        action="Edit" />
      <NewDevelopmentsEdit
        menu="Edit New Developments Details"
        parentPath="new-developments"
        pageType="Edit"
        additionalParams={AdditionalParams} />
    </div>
  </>
)

export default NewDevelopmentsEditPage;
